<template>
  <div class="d-flex btnarea">
    <p>{{ $t("product-page.flight-details") }}</p>
    <p>
      <a href="#" onclick="return false;" v-b-modal="'fulldetails'">{{
        $t("product-page.full-details")
      }}</a>
    </p>

    <b-modal hide-footer id="fulldetails" size="xl">
      <template #modal-title>
        <h4 class="modal-title">{{ $t("product-page.full-flight-details") }}</h4>
      </template>

      <div class="flightdetails_box">
        <h3>
          {{ $t("product-page.departure-flight") }} – {{ $t("product-page.charter") }}
        </h3>
        <div class="flightdetails_inner">
          <div class="d-flex justify-content-between">
            <div class="iconbox">
              <img src="/assets/img/blueair.jpg" alt="" />
            </div>
            <div class="textbox1">
              <p>
                <strong>{{ departure.FL_Dep_Hour }}</strong
                >{{ depaturePlace1 }} {{ getWeek(departure.FL_Date) }} {{ departureDate }}
              </p>
            </div>
            <div class="textbox2">
              <div class="d-flex justify-content-between">
                <p>
                  <strong>{{ departureFrom }}</strong>
                </p>
                <p>{{ $t("product-page.direct-flight") }}</p>
                <p>
                  <strong>{{ departureTo }}</strong>
                </p>
              </div>
              <p>{{ $t("product-page.flight-duration") }}:{{ departure.time }}</p>
            </div>
            <div class="textbox3">
              <p>
                <strong>{{ departure.FL_Arrv_Hour }}</strong
                >{{ arrivePlace1 }} {{ getWeek(departure.FL_Date) }} {{ departureDate }}
              </p>
            </div>
          </div>
          <div class="d-flex flightbtn">
            <div class="p-2 flex-fill">
              {{ $t("product-page.flight-number") }}:{{ departure.FL_Flt_Number }}
            </div>
            <div class="p-2 flex-fill">
              <a href="#" onclick="return false;" type="button" v-b-toggle="'Collapse1'"
                >{{ $t("product-page.flight-details") }}<i class="fas fa-chevron-down"></i
              ></a>
            </div>
          </div>
          <b-collapse id="Collapse1">
            <div class="phone_right">
              <ul>
                <li>
                  {{ $t("product-page.baby-age") }}:
                  {{ $t("product-page.baby-age-content") }}
                </li>
                <li>{{ $t("product-page.link-content") }}</li>
                <!-- <li>{{ $t("product-page.transfer-content") }}</li> -->
              </ul>
            </div>
          </b-collapse>

          <div class="d-flex justify-content-between lagubag">
            <div class="d-flex">
              <i class="customIcon icon-bag"><span class="count">1</span></i
              >{{ $t("product-page.hand-luggage") }}
            </div>
            <div class="">
              <strong>{{ departure.FL_Max_Weight_Hand }}KG</strong>
            </div>
          </div>
          <div class="d-flex justify-content-between lagubag">
            <div class="d-flex">
              <i class="customIcon icon-luggage"><span class="count">1</span></i
              >{{ $t("product-page.suitcase") }}
            </div>
            <div class="">
              <strong>{{ departure.FL_Max_Weight }}KG</strong>
            </div>
          </div>

          <div class="remark">
            <p>{{ departure.Authorization_msg }}</p>
          </div>
        </div>
      </div>

      <div class="flightdetails_box">
        <h3>{{ $t("product-page.return-flight-charter") }}</h3>
        <div class="flightdetails_inner">
          <div class="d-flex justify-content-between">
            <div class="iconbox">
              <img src="/assets/img/blueair.jpg" alt="" />
            </div>
            <div class="textbox1">
              <p>
                <strong>{{ returnArrive.FL_Arrv_Hour }}</strong
                >{{ arrivePlace2 }} {{ getWeek(returnArrive.FL_Date) }} {{ returnDate }}
              </p>
            </div>
            <div class="textbox2">
              <div class="d-flex justify-content-between">
                <p>
                  <strong>{{ arriveFrom }}</strong>
                </p>
                <p>{{ $t("product-page.direct-flight") }}</p>
                <p>
                  <strong>{{ arriveTo }}</strong>
                </p>
              </div>
              <p>{{ $t("product-page.flight-duration") }}:{{ returnArrive.time }}</p>
            </div>
            <div class="textbox3">
              <p>
                <strong>{{ returnArrive.FL_Dep_Hour }}</strong
                >{{ depaturePlace2 }} {{ getWeek(returnArrive.FL_Date) }} {{ returnDate }}
              </p>
            </div>
          </div>
          <div class="d-flex flightbtn">
            <div class="p-2 flex-fill">
              {{ $t("product-page.flight-number") }}:{{ returnArrive.FL_Flt_Number }}
            </div>
            <div class="p-2 flex-fill">
              <a href="#" onclick="return false;" type="button" v-b-toggle="'Collapse2'"
                >{{ $t("product-page.flight-details") }}
                <i class="fas fa-chevron-down"></i
              ></a>
            </div>
          </div>
          <b-collapse id="Collapse2">
            <div class="phone_right">
              <ul>
                <li>
                  {{ $t("product-page.baby-age") }}:
                  {{ $t("product-page.baby-age-content") }}
                </li>
                <li>{{ $t("product-page.link-content") }}</li>
                <!-- <li>{{ $t("product-page.transfer-content") }}</li> -->
              </ul>
            </div>
          </b-collapse>

          <div class="d-flex justify-content-between lagubag">
            <div class="d-flex">
              <i class="customIcon icon-bag"> <span class="count">1</span></i
              >{{ $t("product-page.hand-luggage") }}
            </div>
            <div class="">
              <strong>{{ returnArrive.FL_Max_Weight_Hand }}KG</strong>
            </div>
          </div>
          <div class="d-flex justify-content-between lagubag">
            <div class="d-flex">
              <i class="customIcon icon-luggage"> <span class="count">1</span></i
              >{{ $t("product-page.suitcase") }}
            </div>
            <div class="">
              <strong>{{ returnArrive.FL_Max_Weight }}KG</strong>
            </div>
          </div>

          <div class="remark">
            <p>{{ returnArrive.Authorization_msg }}</p>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { VBModal, BModal, BCollapse, VBToggle } from 'bootstrap-vue';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';

export default {
  components: {
    BModal,
    BCollapse,
  },
  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
  },
  props: {
    flights: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      departure: null,
      returnArrive: null,

      query: null,
      flight: null,
      depaturePlace1: '',
      arrivePlace1: '',
      depaturePlace2: '',
      arrivePlace2: '',

      departureDate: '',
      returnDate: '',

      destinationList: [],

      departureFrom: '',
      departureTo: '',
      arriveFrom: '',
      arriveTo: '',
    };
  },
  computed: {
    ...mapGetters({
      categoriy: 'GET_CURRENT_CATEGORY',
      lang: 'GET_LANGUAGE',
    }),
  },
  watch: {
    categoriy() {
      this.destinationList = this.category.destinations;
      this.updateData();
    },
    lang: 'updateLabelWithLang',
  },
  async beforeMount() {
    this.query = this.$route.query;
    this.flight = this.flights.find((item) => this.query.flights === `${item.fl_id1}${item.fl_id2}`);

    this.departure = this.flight.FlightDetail[0];
    this.returnArrive = this.flight.FlightDetail[1];

    if (this.destinationList === undefined) {
      this.destinationList = await this.$store.dispatch('UPDATE_CURRENT_CATEGORY', {
        categoryId: this.query.categoryId,
      });
    }
    this.updateLabelWithLang();
  },
  mounted() {
    if (this.destinationList) this.updateData();
  },
  methods: {
    updateLabelWithLang() {
      this.departureFrom = (this.flight.translations && this.flight.translations.flightDestinationName[this.departure.FL_From_Route][this.lang]) || this.departure.FL_From_Route;
      this.departureTo = (this.flight.translations && this.flight.translations.flightDestinationName[this.departure.FL_To_Route][this.lang]) || this.departure.FL_To_Route;
      this.arriveFrom = (this.flight.translations && this.flight.translations.flightDestinationName[this.returnArrive.FL_To_Route][this.lang]) || this.returnArrive.FL_To_Route;
      this.arriveTo = (this.flight.translations && this.flight.translations.flightDestinationName[this.returnArrive.FL_From_Route][this.lang]) || this.returnArrive.FL_From_Route;
    },
    getWeek(str) {
      const week = [
        this.$t('weekAllName.sun'),
        this.$t('weekAllName.mon'),
        this.$t('weekAllName.tue'),
        this.$t('weekAllName.wed'),
        this.$t('weekAllName.thu'),
        this.$t('weekAllName.fri'),
        this.$t('weekAllName.sat'),
      ];
      return week[dayjs(str).day()];
    },
    updateData() {
      let destItem = this.destinationList.find((item) => item.code === this.departure.FL_From_Route);
      this.depaturePlace1 = destItem ? destItem.name : this.departure.FL_From_Route;

      destItem = this.destinationList.find((item) => item.code === this.departure.FL_To_Route);
      this.arrivePlace1 = destItem ? destItem.name : this.departure.FL_To_Route;

      destItem = this.destinationList.find((item) => item.code === this.returnArrive.FL_From_Route);
      this.depaturePlace2 = destItem ? destItem.name : this.returnArrive.FL_From_Route;

      destItem = this.destinationList.find((item) => item.code === this.returnArrive.FL_To_Route);
      this.arrivePlace2 = destItem ? destItem.name : this.returnArrive.FL_To_Route;

      this.departureDate = dayjs(this.departure.FL_Date).format('DD/MM');
      this.returnDate = dayjs(this.returnArrive.FL_Date).format('DD/MM');
    },
  },
};
</script>
<style>
#fulldetails {
  padding-left: 0 !important;
}
.modal-header .close {
  padding: 0;
  margin: 0;
}
</style>
<style scoped>
div.btnarea {
  border: none;
  padding: 10px;
  background-color: #eee;
  border-radius: 0;
  margin-top: 10px;
}
.btnarea p {
  margin: 0;
}
</style>
